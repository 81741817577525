import React from "react";
import { FaPlus } from "react-icons/fa6";
import { IoMdSend } from "react-icons/io";
import { FaImage } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa6";

const SendMessageForm = ({
  handleUploadImageVideoOpen,
  openImageVideoUpload,
  handleUploadImage,
  handleUploadVideo,
  handleSendMessage,
  handleOnChange,
  message,
  handleFocus,
  handleBlur,
}) => {
  return (
    <section className="h-16 bg-white flex items-center px-4">
      <div className="relative">
        <button
          onClick={handleUploadImageVideoOpen}
          className="flex justify-center items-center w-11 h-11 rounded-full hover:bg-primary hover:text-white"
        >
          <FaPlus size={20} />
        </button>

        {openImageVideoUpload && (
          <div className="bg-white shadow rounded absolute bottom-14 w-36 p-2">
            <form>
              <label
                htmlFor="uploadImage"
                className="flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer"
              >
                <div className="text-primary">
                  <FaImage size={18} />
                </div>
                <p>Image</p>
              </label>
              <input
                type="file"
                id="uploadImage"
                onChange={handleUploadImage}
                className="hidden"
              />

              <label
                htmlFor="uploadVideo"
                className="flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer"
              >
                <div className="text-purple-500">
                  <FaVideo size={18} />
                </div>
                <p>Video</p>
              </label>
              <input
                type="file"
                id="uploadVideo"
                onChange={handleUploadVideo}
                className="hidden"
              />
            </form>
          </div>
        )}
      </div>

      <form className="h-full w-full flex gap-2" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Type here message..."
          className="py-1 px-4 outline-none w-full h-full"
          value={message.text}
          onChange={handleOnChange}
          // onFocus={handleFocus} // Trigger "typing" event on focus
          // onBlur={handleBlur} // Trigger "stop typing" event on blur
        />
        <button className="text-primary hover:text-secondary">
          <IoMdSend size={28} />
        </button>
      </form>
    </section>
  );
};

export default SendMessageForm;
