import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

// // Initialize the socket instance with the backend URL and authentication token
// const socket = io(process.env.REACT_APP_BACKEND_URL, {
//   auth: {
//     token: localStorage.getItem("token"),
//   },
// });

// Create the context
const SocketContext = createContext(null);

// Create a provider component to wrap your app
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  //
  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
      transports: ["websocket"],
    });
    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
      console.log("dddd");
    };
  }, []);
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

// Custom hook to use the socket in functional components
export const useSocket = () => useContext(SocketContext);

export default SocketContext;
