import { useRef, useState } from "react";

const useCallHook = () =>
  // {
  // setShowVideoPopup,
  // setIsCallStarted,
  // peerConnectionRef,
  // incomingCall,
  // receiverIDRef,
  // socket,
  // setIncomingCall,
  // resetPeerConnection,
  // }
  {
    // const localStreamRef = useRef(null);
    // const remoteStreamRef = useRef(null);
    // const isWaitingForReceiverRef = useRef(false);
    // const iceCandidatesQueue = useRef([]);

    // const [isCalling, setIsCalling] = useState(false);
    // const [isRinging, setIsRinging] = useState(false);

    // const resetStatus = () => {
    //   setIsCalling(false);
    //   setIsRinging(false);
    //   // setIsRemoteStreamVisible(false);
    //   setIsCallStarted(false);
    //   setIncomingCall(null);
    //   setShowVideoPopup(false);
    //   isWaitingForReceiverRef.current = false;
    //   resetPeerConnection();
    //   stopStream();
    // };

    const handleError = async (fn, purpose = "Operation") => {
      try {
        await fn();
      } catch (error) {
        console.error(`Error during ${purpose}:`, error);

        switch (error.name) {
          case "NotFoundError":
            alert("No camera or microphone found. Please connect a device.");
            break;
          case "NotAllowedError":
            alert("Permission to access the camera or microphone was denied.");
            break;
          case "OverconstrainedError":
            alert("Constraints for the camera or microphone are not met.");
            break;
          case "AbortError":
            alert("The media capture process was aborted.");
            break;
          case "NotReadableError":
            alert("There was an issue accessing the media input.");
            break;
          default:
            alert(`An unexpected error occurred: ${error.message}`);
            break;
        }
      }
    };

    const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

    const mediaDeviceAccess = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some((device) => device.kind === "videoinput");
      const hasMicrophone = devices.some(
        (device) => device.kind === "audioinput"
      );

      if (!hasCamera || !hasMicrophone) {
        throw new Error("Camera or microphone not found.");
      }
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const videoConstraints = {
        width: { min: 640, ideal: 1280, max: screenWidth },
        height: { min: 480, ideal: 720, max: screenHeight },
      };
      const stream = await navigator.mediaDevices.getUserMedia({
        video: isMobileDevice()
          ? { width: 640, height: 360 } // Lower resolution for mobile
          : { width: 1280, height: 720 }, // Higher resolution for desktop,
        audio: true,
      });

      return stream;
    };

    // const handleStartCall = async ({
    //   isStartingCall = false,
    //   paramIncomingCall,
    // }) => {
    //   setShowVideoPopup(true);
    //   setIsCallStarted(true);

    //   if (isStartingCall) {
    //     isWaitingForReceiverRef.current = true;
    //   }

    //   const callParticipant = isStartingCall
    //     ? receiverIDRef.current
    //     : paramIncomingCall?.from || incomingCall?.from;

    //   await handleError(async () => {
    //     const stream = await permissionCheck();
    //     localStreamRef.current.srcObject = stream;

    //     peerConnectionRef.current.onicecandidate = ({ candidate }) => {
    //       if (candidate) {
    //         const encodedCandidate = encodeURIComponent(
    //           JSON.stringify(candidate)
    //         );
    //         socket.emit("sending-ice-candidate", {
    //           candidate: encodedCandidate,
    //           to: callParticipant,
    //         });
    //       }
    //     };

    //     peerConnectionRef.current.ontrack = (event) => {
    //       remoteStreamRef.current.srcObject = event.streams[0];
    //     };

    //     stream.getTracks().forEach((track) => {
    //       peerConnectionRef.current.addTrack(track, stream);
    //     });

    //     if (isStartingCall) {
    //       const offer = await peerConnectionRef.current.createOffer({
    //         iceRestart: true,
    //       });
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       socket.emit("calling-user", { offer, to: receiverIDRef.current });

    //       socket.emit(
    //         "check-user-online",
    //         { to: receiverIDRef.current },
    //         (isOnline) => {
    //           setIsRinging(isOnline);
    //           if (!isOnline) setIsCalling(true);
    //         }
    //       );
    //     } else {
    //       const { offer } = incomingCall || paramIncomingCall;

    //       await peerConnectionRef.current.setRemoteDescription(
    //         new RTCSessionDescription(offer)
    //       );

    //       const answer = await peerConnectionRef.current.createAnswer();
    //       await peerConnectionRef.current.setLocalDescription(answer);

    //       socket.emit("sending-call-answer", { answer, to: callParticipant });

    //       iceCandidatesQueue.current.forEach(async (candidate) => {
    //         await peerConnectionRef.current.addIceCandidate(
    //           new RTCIceCandidate(candidate)
    //         );
    //       });
    //       iceCandidatesQueue.current = [];
    //       setIncomingCall(null);
    //       setIsCalling(false);
    //       setIsRinging(false);
    //     }
    //   }, "Starting call");
    // };

    // const handleStartCall = () =>
    //   handleError(async () => {
    //     setShowVideoPopup(true);
    //     setIsCallStarted(true);
    //     isWaitingForReceiverRef.current = true;

    //     try {
    //       const stream = await permissionCheck();

    //       localStreamRef.current.srcObject = stream;

    //       peerConnectionRef.current.onicecandidate = ({ candidate }) => {
    //         if (candidate) {
    //           const encodedCandidate = encodeURIComponent(
    //             JSON.stringify(candidate)
    //           );
    //           socket.emit("sending-ice-candidate", {
    //             candidate: encodedCandidate,
    //             to: receiverIDRef.current,
    //           });
    //         }
    //       };

    //       peerConnectionRef.current.ontrack = (event) => {
    //         remoteStreamRef.current.srcObject = event.streams[0];
    //       };

    //       stream.getTracks().forEach((track) => {
    //         peerConnectionRef.current.addTrack(track, stream);
    //       });

    //       const offer = await peerConnectionRef.current.createOffer({
    //         iceRestart: true,
    //       });
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       console.log("Sending call offer");
    //       socket.emit("calling-user", { offer, to: receiverIDRef.current });

    //       socket.emit(
    //         "check-user-online",
    //         { to: receiverIDRef.current },
    //         (isOnline) => {
    //           setIsRinging(isOnline);
    //           if (!isOnline) setIsCalling(true);
    //         }
    //       );
    //     } catch (error) {
    //       handleError(error);
    //     }
    //   }, "Handle Call");

    // const handleAnswerCall = async (paramIncomingCall) => {
    //   const { from, offer } = incomingCall || paramIncomingCall;
    //   receiverIDRef.current = from;

    //   try {
    //     const { signalingState } = peerConnectionRef.current;
    //     if (
    //       signalingState === "stable" ||
    //       signalingState === "have-local-offer"
    //     ) {
    //       peerConnectionRef.current.onicecandidate = ({ candidate }) => {
    //         if (candidate) {
    //           const encodedCandidate = encodeURIComponent(
    //             JSON.stringify(candidate)
    //           );
    //           socket.emit("sending-ice-candidate", {
    //             to: from,
    //             candidate: encodedCandidate,
    //           });
    //         }
    //       };

    //       peerConnectionRef.current.ontrack = (event) => {
    //         remoteStreamRef.current.srcObject = event.streams[0];
    //       };

    //       await peerConnectionRef.current.setRemoteDescription(
    //         new RTCSessionDescription(offer)
    //       );

    //       const stream = await navigator.mediaDevices.getUserMedia({
    //         video: isMobileDevice()
    //           ? { width: 640, height: 360 }
    //           : { width: 1280, height: 720 },
    //         audio: true,
    //       });

    //       localStreamRef.current.srcObject = stream;
    //       stream
    //         .getTracks()
    //         .forEach((track) =>
    //           peerConnectionRef.current.addTrack(track, stream)
    //         );

    //       const answer = await peerConnectionRef.current.createAnswer();
    //       await peerConnectionRef.current.setLocalDescription(answer);

    //       socket.emit("sending-call-answer", { answer, to: from });

    //       iceCandidatesQueue.current.forEach(async (candidate) => {
    //         await peerConnectionRef.current.addIceCandidate(
    //           new RTCIceCandidate(candidate)
    //         );
    //       });
    //       iceCandidatesQueue.current = [];

    //       setIsCallStarted(true);
    //       setShowVideoPopup(true);
    //       setIncomingCall(null);
    //       setIsCalling(false);
    //       setIsRinging(false);
    //       isWaitingForReceiverRef.current = false;
    //     } else {
    //       console.warn(
    //         "Cannot handle incoming call. Current signaling state:",
    //         signalingState
    //       );
    //     }
    //   } catch (error) {
    //     console.error("Error handling incoming call:", error);
    //   }
    // };

    // const handleEndCall = (
    //   isRejectingCall = false,
    //   callEndByOtherParty = false
    // ) => {
    //   if (!callEndByOtherParty) {
    //     const eventType = isRejectingCall ? "call-rejected" : "end-call";
    //     const targetID = isRejectingCall
    //       ? incomingCall?.from
    //       : receiverIDRef.current;
    //     socket.emit(eventType, { to: targetID });
    //   }

    //   if (peerConnectionRef.current) {
    //     peerConnectionRef.current.close();
    //     peerConnectionRef.current = null;
    //   }

    //   resetStatus();
    // };

    // const stopStream = () => {
    //   const localStream = localStreamRef.current?.srcObject;
    //   if (localStream) {
    //     localStream.getTracks().forEach((track) => track.stop());
    //     localStreamRef.current.srcObject = null;
    //   }

    //   const remoteStream = remoteStreamRef.current?.srcObject;
    //   if (remoteStream) {
    //     remoteStream.getTracks().forEach((track) => track.stop());
    //     remoteStreamRef.current.srcObject = null;
    //   }
    // };

    return {
      // handleStartCall,
      // handleEndCall,
      // stopStream,
      // localStreamRef,
      // remoteStreamRef,
      // isCalling,
      // isRinging,
      // resetStatus,
      // setIsCalling,
      // setIsRinging,
      // handleAnswerCall,
      mediaDeviceAccess,
      handleError,
    };
  };

export default useCallHook;
