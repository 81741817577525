import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./Header.js";
import ChatContainer from "./MessageContainer.js";
import UploadPreview from "./UploadPreview.js";
import SendMessageForm from "./SendMessage.js";
import backgroundImage from "../../assets/wallapaper.jpeg";
import { useCallContext } from "../../context/CallContext.js";
import { useSocket } from "../../context/SocketContext.js"; // Adjust the import path accordingly
import { debounce } from "lodash";

const MessagePage = () => {
  const params = useParams();
  const socket = useSocket();

  const user = useSelector((state) => state?.user);
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    _id: "",
  });

  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: "",
  });

  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  // const currentMessage = useRef(null);

  const chatContainerRef = useRef(null);
  const [isChatInFocus, setIsChatInFocus] = useState(true); // Track if chat is in focus

  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false); // To
  let typingTimeout;

  const chatPartner = params?.userId;
  const currentUser = user?._id;

  const { receiverIDRef, handleStartCall, isCallStarted } = useCallContext();

  const handleUploadImageVideoOpen = () =>
    setOpenImageVideoUpload(!openImageVideoUpload);
  const handleClearUploadImage = () => setMessage({ ...message, imageUrl: "" });
  const handleClearUploadVideo = () => setMessage({ ...message, videoUrl: "" });

  useEffect(() => {
    // Handle visibility state of the document (whether chat is focused)
    const handleVisibilityChange = () => {
      setIsChatInFocus(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      if (receiverIDRef) receiverIDRef.current = params.userId;

      socket.emit("message-page", params.userId);

      socket.on("message-user", (data) => {
        setDataUser(data);
      });

      socket.on("message", (data) => {
        setAllMessage(data);
      });

      socket.on("typing", (data) => {
        if (data.sender === params?.userId) {
          setIsTyping(true);
        }
      });

      socket.on("stop typing", (data) => {
        if (data.sender === params?.userId) {
          setIsTyping(false);
        }
      });

      socket.on("message-seen", ({ messageIds }) => {
        setAllMessage((prevMessages) =>
          prevMessages.map((msg) =>
            messageIds.includes(msg._id) ? { ...msg, seen: true } : msg
          )
        );
      });

      return () => {
        socket.off("message-user");
        socket.off("message");
        socket.off("typing");
        socket.off("stop typing");
        socket.off("message-seen");
      };
    }
  }, [socket, params?.userId, user]);

  // Use lodash's debounce function to delay the stop typing event by 3 seconds
  const handleStopTypingDebounced = debounce(() => {
    setTyping(false);
    socket.emit("stop typing", {
      sender: user?._id,
      receiver: params?.userId,
    });
  }, 3000);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", { sender: user?._id, receiver: params?.userId });
    }
    setMessage((preve) => {
      return {
        ...preve,
        text: value,
      };
    });

    // Use lodash debounce to emit "stop typing" after 3 seconds of inactivity
    handleStopTypingDebounced();
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (message.text || message.imageUrl || message.videoUrl) {
      if (socket) {
        socket.emit("new-message", {
          sender: user?._id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?._id,
        });
        setMessage({
          text: "",
          imageUrl: "",
          videoUrl: "",
        });
        socket.emit("stop typing", {
          sender: user?._id,
          receiver: params.userId,
        });
      }
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const localUrl = URL.createObjectURL(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => {
      return {
        ...preve,
        imageUrl: localUrl,
      };
    });
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const localUrl = URL.createObjectURL(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => {
      return {
        ...preve,
        videoUrl: localUrl,
      };
    });
  };

  // const handleInput = () => {
  //   socket.emit("typing", { sender: currentUser, receiver: chatPartner });

  //   clearTimeout(typingTimeout);
  //   typingTimeout = setTimeout(() => {
  //     socket.emit("stop typing", {
  //       sender: currentUser,
  //       receiver: chatPartner,
  //     });
  //   }, 1000);
  // };
  // const handleFocus = () => {
  //   socket.emit("typing", { sender: currentUser, receiver: chatPartner });
  // };

  // const handleBlur = () => {
  //   socket.emit("stop typing", { sender: currentUser, receiver: chatPartner });
  // };
  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="bg-no-repeat bg-cover"
    >
      <Header
        receiverUser={dataUser}
        currentUser={user}
        onStartCall={handleStartCall}
        callBtnVisible={!isCallStarted}
        isTyping={isTyping}
      />
      <ChatContainer
        allMessage={allMessage}
        chatContainerRef={chatContainerRef}
        user={user}
        isChatInFocus={isChatInFocus}
        socket={socket}
        params={params}
      />
      <UploadPreview
        message={message}
        handleClearUploadImage={handleClearUploadImage}
        handleClearUploadVideo={handleClearUploadVideo}
      />
      <SendMessageForm
        handleUploadImageVideoOpen={handleUploadImageVideoOpen}
        openImageVideoUpload={openImageVideoUpload}
        handleUploadImage={handleUploadImage}
        handleUploadVideo={handleUploadVideo}
        handleSendMessage={handleSendMessage}
        handleOnChange={handleOnChange}
        message={message}
        // handleFocus={handleFocus} // Trigger "typing" event on focus
        // handleBlur={handleBlur} // Trigger "stop typing" event on blur
      />
    </div>
  );
};

export default MessagePage;
