// PrivateRoute.jsx
import React from "react";
import { Navigate } from "react-router-dom";

const useAuth = () => {
  // Return true if the user is authenticated, false otherwise
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated;
};

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useAuth();

  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/email" />;
  }
};

export default PrivateRoute;
