import { createContext, useContext, useState, useRef, useEffect } from "react";
import Call from "../components/call/index.js";
// import DraggablePopup from "../components/draggablePopup/DraggablePopup.js";
import { useSocket } from "./SocketContext.js"; // Adjust the import path accordingly
import CallModal from "../components/call/CallModal.js";

const CallContext = createContext();

export const CallProvider = ({ children }) => {
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const videoCompRef = useRef();
  const [isRemoteStreamVisible, setIsRemoteStreamVisible] = useState(false);
  const peerConnectionRef = useRef(null);
  const receiverIDRef = useRef(null);
  const socket = useSocket();
  // const remoteStreamRef = useRef(null);
  // const screenStreamRef = useRef(null); // Store screen stream
  // const screenShareVideoRef = useRef(null);

  const handleStartCall = async () => {
    await videoCompRef.current.handleStartCall();
  };

  const handleEndCall = () => {
    videoCompRef.current.handleEndCall();
  };

  const handleAnswerCall = () => {
    videoCompRef.current.handleAnswerCall();
  };

  const handleRejectCall = () => {
    videoCompRef.current.handleEndCall({
      isRejectingCall: true,
    });
  };

  const setupWebRTC = () => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.oniceconnectionstatechange = null; // Clear event listeners
      peerConnectionRef.current.close(); // Close the WebRTC connection
      peerConnectionRef.current = null; // Clear the reference
    }

    const iceServersLocal = [{ urls: "stun:stun.l.google.com:19302" }];

    const iceServersLive = [
      {
        urls: "stun:stun.relay.metered.ca:80",
      },
      {
        urls: "turn:in.relay.metered.ca:80",
        username: "0fe673651ddd552463298587",
        credential: "fET5R7/b/uQ/AsA4",
      },
      {
        urls: "turn:in.relay.metered.ca:80?transport=tcp",
        username: "0fe673651ddd552463298587",
        credential: "fET5R7/b/uQ/AsA4",
      },
      {
        urls: "turn:in.relay.metered.ca:443",
        username: "0fe673651ddd552463298587",
        credential: "fET5R7/b/uQ/AsA4",
      },
      {
        urls: "turns:in.relay.metered.ca:443?transport=tcp",
        username: "0fe673651ddd552463298587",
        credential: "fET5R7/b/uQ/AsA4",
      },
    ];

    const iceServers = process.env.REACT_APP_BACKEND_URL.includes("localhost")
      ? iceServersLocal
      : iceServersLive;

    const peerConnection = new RTCPeerConnection({
      iceServers,
      // iceCandidatePoolSize: 10,
    });

    peerConnectionRef.current = peerConnection;

    // Ontrack event: Listen for both streams (camera and screen share)
    // Handle incoming remote tracks (either video or screen share)
    // peerConnection.ontrack = (event) => {
    //   const [remoteStream] = event.streams;

    //   if (event.track.kind === "video") {
    //     if (isScreenShare(event.track)) {
    //       screenStreamRef.current.srcObject = remoteStream;
    //     } else {
    //       remoteStreamRef.current.srcObject = remoteStream;
    //     }
    //   }
    // };

    // // Helper function to identify screen share track
    // const isScreenShare = (track) => {
    //   // You could use the label or metadata to distinguish between streams
    //   return track.label.includes("screen");
    // };
    // Handle ICE connection state change
    peerConnection.oniceconnectionstatechange = () => {
      const state = peerConnection.iceConnectionState;
      console.log("ICE connection state:", state);
      if (state === "disconnected" || state === "failed") {
        console.warn("ICE connection lost. Reconnecting...");
        // Optionally, you could attempt to reconnect or notify the user
      }
    };
  };

  useEffect(() => {
    if (socket) {
      setupWebRTC();

      return () => {
        if (peerConnectionRef.current) {
          peerConnectionRef.current.close();
          peerConnectionRef.current = null;
        }
      };
    }
  }, [socket]);

  const resetPeerConnection = () => {
    setupWebRTC(); // Recreate the peer connection
  };

  return (
    <CallContext.Provider
      value={{
        handleAnswerCall,
        handleRejectCall,
        handleStartCall,
        handleEndCall,
        isCallStarted,
        receiverIDRef,
      }}
    >
      {children}
      {incomingCall && (
        <CallModal
          callFrom={incomingCall?.from_name || ""}
          startCall={handleAnswerCall}
          rejectCall={handleRejectCall}
        />
      )}
      {/* {incomingCall && (
        <div className="incoming-call-popup">
          <h3>Incoming Call</h3>
          <p>Caller ID: {incomingCall?.from_name || ""}</p>
          <button onClick={handleAnswerCall}>Accept</button>
          <button onClick={handleRejectCall}>Reject</button>
        </div>
      )} */}
      {/* <DraggablePopup
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
        showPopup={showVideoPopup}
        onClose={handleEndCall}
        title="Video Call"
      > */}
      <div style={{ visibility: !showVideoPopup ? "hidden" : "" }}>
        <Call
          ref={videoCompRef}
          incomingCall={incomingCall}
          setIncomingCall={setIncomingCall}
          setIsCallStarted={setIsCallStarted}
          showVideoPopup={showVideoPopup}
          setShowVideoPopup={setShowVideoPopup}
          resetPeerConnection={resetPeerConnection}
          receiverIDRef={receiverIDRef}
          peerConnectionRef={peerConnectionRef}
          isRemoteStreamVisible={isRemoteStreamVisible}
          setIsRemoteStreamVisible={setIsRemoteStreamVisible}
          // remoteStreamRef={remoteStreamRef}
          // screenStreamRef={screenStreamRef}
          // screenShareVideoRef={screenShareVideoRef}
        />
      </div>
      {/* )} */}
      {/* </DraggablePopup> */}
    </CallContext.Provider>
  );
};

export const useCallContext = () => {
  return useContext(CallContext);
};
