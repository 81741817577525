import logo from "./logo.svg";
import "./App.css";
import { Outlet } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { subscribeToPushNotification } from "./helpers/notification";

function App() {
  const handleSubscribe = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        await subscribeToPushNotification("66db3310c0a03f2656ee3cd7");
        console.log("Subscribed to notifications!");
      } else {
        console.log("Notification permission denied!");
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  };
  useEffect(() => {
    handleSubscribe();
  });
  return (
    <>
      <Toaster />
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default App;
