const MenuPopup = ({ options }) => {
  return (
    <div className="bottom-10 bg-gray-800 text-white p-2 rounded-md shadow-lg w-48 z-50">
      {options.map((option, index) => (
        <button
          key={index} // Always use a unique key in list rendering
          className="block w-full text-left px-3 py-2 hover:bg-gray-700 rounded"
          onClick={option.function} // Fix the typo here
        >
          {option.name}
        </button>
      ))}
    </div>
  );
};

export default MenuPopup;
