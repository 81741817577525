import React, { useEffect, useState } from "react";
import MessageItem from "./MessageItem";

const ChatContainer = ({
  allMessage,
  chatContainerRef,
  user,
  isChatInFocus,
  socket,
  params,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [longPressTriggered, setLongPressTriggered] = useState("");

  // Check if user is scrolled to the bottom of the chat
  const isUserAtBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    return (
      chatContainer.scrollHeight - chatContainer.scrollTop <=
      chatContainer.clientHeight + 10 // Add a small buffer
    );
  };

  // Scroll to bottom when a new message is sent by the sender or when chat opens
  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      if (isUserAtBottom() && isChatInFocus) {
        const unseenMessages = allMessage.filter(
          (msg) => !msg.seen && msg.msgByUserId !== user._id
        );

        if (unseenMessages.length > 0) {
          socket.emit("receiver-seen", {
            userId: params.userId,
            messageIds: unseenMessages.map((msg) => msg._id),
          });
        }
      }
    };

    if (chatContainer) {
      handleScroll();
      chatContainer.addEventListener("scroll", handleScroll);

      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [allMessage, isChatInFocus]);

  useEffect(() => {
    if (allMessage.length > 0) {
      scrollToBottom();
    }
  }, [allMessage]);

  const handleDelete = (msg) => {
    //  setShowMenu(false);
    socket.emit("delete-message", {
      messageId: msg._id,
      senderId: user._id,
      receiverId: params.userId, // Assuming you have this info
    });
  };

  const handleEdit = (msg) => {
    const newText = prompt("Edit your message:", msg.text);
    if (newText) {
      //  setShowMenu(false);
      socket.emit("edit-message", {
        messageId: msg._id,
        newText: newText,
        senderId: user._id,
        receiverId: params.userId, // Assuming you have this info
      });
    }
  };

  return (
    <section
      ref={chatContainerRef}
      className="h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50"
    >
      <div className="flex flex-col gap-2 py-2 mx-2">
        {allMessage.map((msg, index) => (
          <MessageItem
            key={index}
            msg={msg}
            user={user}
            onDelete={() => handleDelete(msg)}
            onEdit={() => handleEdit(msg)}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            longPressTriggered={longPressTriggered}
            setLongPressTriggered={setLongPressTriggered}
          />
        ))}
      </div>
    </section>
  );
};

export default ChatContainer;
