import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";

const ChatHeader = ({
  currentUser,
  receiverUser,
  onStartCall,
  callBtnVisible,
  isTyping,
}) => {
  const callBtnEnabled = currentUser._id !== receiverUser._id && callBtnVisible;
  return (
    <header className="sticky top-0 h-16 bg-white flex justify-between items-center px-4">
      <div className="flex items-center gap-4">
        <Link to={"/"} className="lg:hidden">
          <FaAngleLeft size={25} />
        </Link>
        <div>
          <Avatar
            width={50}
            height={50}
            imageUrl={receiverUser?.profile_pic}
            name={receiverUser?.name}
            isOnline={receiverUser.online}
          />
        </div>
        <div>{isTyping && <p>Typing...</p>}</div>
        <div>
          <h3 className="font-semibold text-lg my-0 text-ellipsis line-clamp-1">
            {receiverUser?.name}
          </h3>
          <p className="-my-2 text-sm">
            {receiverUser.online ? (
              <span className="text-primary">online</span>
            ) : (
              <span className="text-slate-400">offline</span>
            )}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <div className={`cursor-pointer ${!callBtnEnabled ? "disabled" : ""}`}>
          <span
            className="material-symbols-outlined"
            onClick={callBtnEnabled ? onStartCall : null}
          >
            video_call
          </span>
        </div>

        <button className="cursor-pointer hover:text-primary">
          <HiDotsVertical />
        </button>
      </div>
    </header>
  );
};

export default ChatHeader;
