import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSocket } from "../../context/SocketContext.js"; // Adjust the import path accordingly
import "./call.css";
import useCallHook from "../../hooks/useCallHook.js";
import classnames from "classnames";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faPhone,
  faDesktop,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./ActionButton.js";

const Call = forwardRef(
  (
    {
      receiverIDRef,
      peerConnectionRef,
      isRemoteStreamVisible,
      setIsRemoteStreamVisible,
      resetPeerConnection,
      incomingCall,
      setIncomingCall,
      setIsCallStarted,
      setShowVideoPopup,
      showVideoPopup,
      // remoteStreamRef,
      // screenShareVideoRef,
      // screenStreamRef,
    },
    ref
  ) => {
    const socket = useSocket();
    const localStreamRef = useRef(null);
    const remoteStreamRef = useRef(null);
    const screenStreamRef = useRef(null); // Store screen stream
    // const screenShareVideoRef = useRef(null);

    // const tempStreamRef = useRef(null);
    const iceCandidatesQueue = useRef([]);

    const [isCalling, setIsCalling] = useState(false);
    const [isRinging, setIsRinging] = useState(false);

    const [isVideoOn, setIsVideoOn] = useState(true); // Track video status
    const [isAudioOn, setIsAudioOn] = useState(true); // Track audio status
    const [isSpeakerOn, setIsSpeakerOn] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [screenShareStart, setScreenShareStart] = useState(false);

    const isWaitingForReceiverRef = useRef(false);

    const { mediaDeviceAccess, handleError } = useCallHook();

    const stopStream = () => {
      const localStream = localStreamRef.current?.srcObject;
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        localStreamRef.current.srcObject = null;
      }

      const remoteStream = remoteStreamRef.current?.srcObject;
      if (remoteStream) {
        remoteStream.getTracks().forEach((track) => track.stop());
        remoteStreamRef.current.srcObject = null;
      }
    };

    const resetStatus = () => {
      setIsCalling(false);
      setIsRinging(false);
      setIsRemoteStreamVisible(false);
      setIsCallStarted(false);
      setIncomingCall(null);
      setShowVideoPopup(false);
      isWaitingForReceiverRef.current = false;
      stopStream();
      resetPeerConnection();
    };

    const cleanupOnUnload = () => {
      socket.emit("end-call", { to: receiverIDRef.current });
      resetStatus();
      // if (socket) {
      //   socket.disconnect();
      // }
    };

    useEffect(() => {
      if (socket) {
        window.addEventListener("beforeunload", cleanupOnUnload);
        window.addEventListener("unload", cleanupOnUnload); // Handle navigation away

        socket.on("receiving-ice-candidate", async (data) => {
          const decodedCandidate = JSON.parse(
            decodeURIComponent(data.candidate)
          );
          if (peerConnectionRef.current.remoteDescription) {
            await peerConnectionRef.current.addIceCandidate(
              new RTCIceCandidate(decodedCandidate)
            );
          } else {
            iceCandidatesQueue.current.push(decodedCandidate);
          }
        });

        socket.on("receiving-call", (data) => {
          console.log("Call offer received from:", data.from);
          if (isWaitingForReceiverRef.current) {
            handleAnswerCall(data);
          } else {
            setIncomingCall(data);
          }
        });

        socket.on("receiving-call-answer", async (data) => {
          try {
            const signalingState = peerConnectionRef.current.signalingState;
            if (
              signalingState === "have-local-offer" ||
              signalingState === "stable"
            ) {
              console.log("Received answer");
              await peerConnectionRef.current.setRemoteDescription(
                new RTCSessionDescription(data.answer)
              );
              setIsCalling(false);
              setIsRinging(false);
              setIsCallStarted(true);
              iceCandidatesQueue.current.forEach(async (candidate) => {
                await peerConnectionRef.current.addIceCandidate(
                  new RTCIceCandidate(candidate)
                );
              });
              iceCandidatesQueue.current = [];
            } else {
              console.warn(
                "Cannot set remote answer. Current signaling state:",
                signalingState
              );
            }
          } catch (error) {
            console.error("Error handling answer:", error);
          }
        });

        socket.on("call-rejected", (data) => {
          console.log("Call rejected by:", data.from);
          handleEndCall({
            isRejectingCall: true,
          });
          // handleRejectCall(true);
        });

        socket.on("end-call", () => {
          console.log("Call ended by the other party");
          // handleEndCall(true); // Pass true to indicate it was ended by the other party
          handleEndCall({
            callEndByOtherParty: true,
          });
        });

        // When renegotiation offer is received
        socket.on("renegotiate-offer", async ({ offer, from }) => {
          try {
            await peerConnectionRef.current.setRemoteDescription(
              new RTCSessionDescription(offer)
            );

            // Create and send an answer back to the sender
            const answer = await peerConnectionRef.current.createAnswer();
            await peerConnectionRef.current.setLocalDescription(answer);

            socket.emit("sending-call-answer", {
              answer,
              to: from,
            });

            console.log("Renegotiation successful, updated video stream.");
          } catch (err) {
            console.error("Error handling renegotiation offer: ", err);
          }
        });

        // Handle incoming offer after renegotiation
        socket.on("screen-sharing", async ({ offer, from }) => {
          await peerConnectionRef.current.setRemoteDescription(
            new RTCSessionDescription(offer)
          );

          const answer = await peerConnectionRef.current.createAnswer();
          await peerConnectionRef.current.setLocalDescription(answer);

          socket.emit("sending-call-answer", {
            answer,
            to: from,
          });
        });

        // Ontrack event: Listen for both camera and screen share streams
        // peerConnectionRef.current.ontrack = (event) => {
        //   const stream = event.streams[0];

        //   // Distinguish between the local camera stream and the screen share stream
        //   if (event.track.kind === "video" && isScreenTrack(event.track)) {
        //     console.log("Screen share received");
        //     screenStreamRef.current.srcObject = stream; // Store the screen share stream separately
        //     // screenShareVideoRef.current.srcObject = stream; // Set screen share video element
        //   } else {
        //     remoteStreamRef.current.srcObject = stream; // Set remote camera video element
        //   }
        // };

        // Helper function to identify screen share track
        const isScreenTrack = (track) => {
          return track.label.includes("screen"); // Adjust this logic as needed
        };

        // Listen for the "stop-screen-share" event from the sender
        socket.on("stop-screen-share", () => {
          console.log("Screen sharing stopped by sender");

          // Stop the screen share video and optionally hide it
          if (screenStreamRef.current) {
            const screenStream = screenStreamRef.current.srcObject;
            if (screenStream) {
              screenStream.getTracks().forEach((track) => track.stop()); // Stop the screen stream
            }
            setScreenShareStart(false);
            screenStreamRef.current.srcObject = null; // Clear the video element
          }
        });

        return () => {
          window.removeEventListener("beforeunload", cleanupOnUnload);
          window.removeEventListener("unload", cleanupOnUnload);

          socket.off("receiving-ice-candidate");
          socket.off("receiving-call");
          socket.off("receiving-call-answer");
          socket.off("call-rejected");
          socket.off("end-call");
        };
      }
    }, [socket]);

    const handleStartCall = () =>
      handleError(async () => {
        setShowVideoPopup(true);
        setIsCallStarted(true);

        isWaitingForReceiverRef.current = true;

        // Get media stream
        const stream = await mediaDeviceAccess();
        localStreamRef.current.srcObject = stream;

        peerConnectionRef.current.onicecandidate = ({ candidate }) => {
          if (candidate) {
            const encodedCandidate = encodeURIComponent(
              JSON.stringify(candidate)
            );

            socket.emit("sending-ice-candidate", {
              candidate: encodedCandidate,
              to: receiverIDRef.current,
            });
          }
        };

        // peerConnectionRef.current.ontrack = (event) => {
        //   remoteStreamRef.current.srcObject = event.streams[0];
        // };

        peerConnectionRef.current.ontrack = (event) => {
          const [remoteStream] = event.streams;

          if (event.track.kind === "video") {
            if (isScreenShare(event.track)) {
              screenStreamRef.current.srcObject = remoteStream;
            } else {
              remoteStreamRef.current.srcObject = remoteStream;
            }
          }
        };

        // Helper function to identify screen share track
        const isScreenShare = (track) => {
          // You could use the label or metadata to distinguish between streams
          return track.label.includes("screen");
        };

        stream.getTracks().forEach((track) => {
          peerConnectionRef.current.addTrack(track, stream);
        });

        // Create and send offer
        const offer = await peerConnectionRef.current.createOffer({
          iceRestart: true,
        });
        await peerConnectionRef.current.setLocalDescription(offer);

        console.log("Sending call offer");
        socket.emit("calling-user", {
          offer,
          to: receiverIDRef.current,
        });

        socket.emit(
          "check-user-online",
          { to: receiverIDRef.current },
          (isOnline) => {
            if (isOnline) {
              setIsRinging(true);
            } else {
              setIsCalling(true);
            }
          }
        );
      }, "Start call");

    const handleAnswerCall = () =>
      handleError(async (paramIncomingCall) => {
        const { from, offer } = incomingCall || paramIncomingCall;
        receiverIDRef.current = from;

        const signalingState = peerConnectionRef.current.signalingState;
        if (
          signalingState === "stable" ||
          signalingState === "have-local-offer"
        ) {
          // Set ICE candidate listener
          peerConnectionRef.current.onicecandidate = ({ candidate }) => {
            if (candidate) {
              const encodedCandidate = encodeURIComponent(
                JSON.stringify(candidate)
              );
              socket.emit("sending-ice-candidate", {
                to: from,
                candidate: encodedCandidate,
              });
            }
          };

          // Track remote stream
          // peerConnectionRef.current.ontrack = (event) => {
          //   remoteStreamRef.current.srcObject = event.streams[0];
          // };

          peerConnectionRef.current.ontrack = (event) => {
            if (event.track.kind === "video") {
              if (remoteStreamRef.current.srcObject) {
                console.log("Screen share received");
                setScreenShareStart(true);

                screenStreamRef.current.srcObject = event.streams[0]; // Show screen share in a separate video element
              } else {
                console.log("Camera video received");
                remoteStreamRef.current.srcObject = event.streams[0]; // Show sender's camera stream
              }
            }
          };

          // Helper function to identify the screen share track
          const isScreenTrack = (track) => {
            return (
              track.label.includes("screen") || track.label.includes("Display")
            ); // Adjust as necessary
          };

          await peerConnectionRef.current.setRemoteDescription(
            new RTCSessionDescription(offer)
          );

          // Get user media
          const stream = await mediaDeviceAccess();

          // Add local stream
          localStreamRef.current.srcObject = stream;
          stream.getTracks().forEach((track) => {
            peerConnectionRef.current.addTrack(track, stream);
          });

          // Create and send answer
          const answer = await peerConnectionRef.current.createAnswer();
          await peerConnectionRef.current.setLocalDescription(answer);

          socket.emit("sending-call-answer", {
            answer,
            to: from,
          });

          // Process any queued ICE candidates after setting remote description
          iceCandidatesQueue.current.forEach(async (candidate) => {
            await peerConnectionRef.current.addIceCandidate(
              new RTCIceCandidate(candidate)
            );
          });
          iceCandidatesQueue.current = [];

          setIsCallStarted(true);
          setShowVideoPopup(true);
          setIncomingCall(null);
          setIsCalling(false);
          setIsRinging(false);
          isWaitingForReceiverRef.current = false;
        } else {
          console.warn(
            "Cannot handle incoming call. Current signaling state:",
            signalingState
          );
        }
      }, "Answer call");

    const handleEndCall = ({
      isRejectingCall = false,
      callEndByOtherParty = false,
    } = {}) => {
      if (!callEndByOtherParty) {
        const eventType = isRejectingCall ? "call-rejected" : "end-call";
        const targetID = isRejectingCall
          ? incomingCall?.from
          : receiverIDRef.current;
        socket.emit(eventType, { to: targetID });
      }

      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }

      resetStatus();
    };

    const toggleMediaTrack = (trackType, setTrackState) => {
      const localStream = localStreamRef.current?.srcObject;
      if (localStream) {
        const tracks =
          trackType === "video"
            ? localStream.getVideoTracks()
            : localStream.getAudioTracks();
        tracks.forEach((track) => (track.enabled = !track.enabled));
        setTrackState((prev) => !prev);
      }
    };

    // const handleScreenShare = async () => {
    //   try {
    //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
    //       video: true,
    //       audio: true, // Optional: share audio as well
    //     });

    //     screenStreamRef.current = screenStream; // Store screen stream for later stopping

    //     const screenTrack = screenStream.getVideoTracks()[0];
    //     const sender = peerConnectionRef.current
    //       .getSenders()
    //       .find((s) => s.track.kind === "video");

    //     if (sender) {
    //       console.log("Sending screen to receiver");

    //       // Add screen track to the peer connection
    //       peerConnectionRef.current.addTrack(screenTrack, screenStream);

    //       // Trigger renegotiation to notify the receiver of the new track
    //       const offer = await peerConnectionRef.current.createOffer();
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       socket.emit("calling-user", {
    //         offer,
    //         to: receiverIDRef.current,
    //       });
    //     }

    //     // Handle screen sharing stop event
    //     screenTrack.onended = async () => {
    //       console.log("Screen sharing stopped");

    //       // Restore camera stream on end
    //       const originalStream = localStreamRef.current.srcObject;
    //       const videoTrack = originalStream.getVideoTracks()[0];
    //       videoTrack.enabled = true; // Re-enable the camera stream

    //       remoteStreamRef.current.srcObject = originalStream; // Restore remote camera stream display

    //       screenStreamRef.current = null; // Clear screen stream ref

    //       // Trigger renegotiation again after stopping screen sharing
    //       const offer = await peerConnectionRef.current.createOffer();
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       socket.emit("calling-user", {
    //         offer,
    //         to: receiverIDRef.current,
    //       });
    //     };
    //   } catch (err) {
    //     console.error("Error sharing screen: ", err);
    //   }
    // };

    // const handleScreenShare = async () => {
    //   try {
    //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
    //       video: true,
    //     });
    //     // setScreenStream(screenStream);

    //     // Add screen share track to the peer connection
    //     screenStream.getTracks().forEach((track) => {
    //       peerConnectionRef.addTrack(track, screenStream);
    //     });

    //     // Handle when screen share stops
    //     screenStream.getVideoTracks()[0].onended = () => {
    //       stopScreenShare();
    //     };
    //   } catch (error) {
    //     console.error("Error starting screen share:", error);
    //   }
    // };

    // const handleScreenShare = async () => {
    //   try {
    //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
    //       video: true,
    //       audio: true, // Optional: share audio as well
    //     });

    //     screenStreamRef.current = screenStream; // Store screen stream for later stopping

    //     const screenTrack = screenStream.getVideoTracks()[0];
    //     const sender = peerConnectionRef.current
    //       .getSenders()
    //       .find((s) => s.track.kind === "video");

    //     if (sender) {
    //       // Add screen track to peer connection without removing the camera stream
    //       peerConnectionRef.current.addTrack(screenTrack, screenStream);
    //       setScreenShareStart(true);
    //     }

    //     // Handle screen share stop event
    //     screenTrack.onended = () => {
    //       console.log("Screen sharing stopped");
    //       // Restore only camera stream on end
    //       const videoTrack =
    //         localStreamRef.current.srcObject.getVideoTracks()[0];
    //       videoTrack.enabled = true; // Re-enable the camera stream

    //       remoteStreamRef.current.srcObject = localStreamRef.current.srcObject; // Restore remote camera stream display
    //       screenStreamRef.current = null; // Clear screen stream ref
    //       setScreenShareStart(false);
    //     };
    //   } catch (err) {
    //     console.error("Error sharing screen: ", err);
    //   }
    // };

    const handleScreenShare = async () => {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });

        // screenStreamRef.current = screenStream; // Store screen stream for stopping later

        const screenTrack = screenStream.getVideoTracks()[0];
        const sender = peerConnectionRef.current
          .getSenders()
          .find((s) => s.track.kind === "video");

        if (sender) {
          if (sender) {
            console.log("Sending screen to receiver");
            setScreenShareStart(true);
            screenStream.getTracks().forEach((track) => {
              peerConnectionRef.current.addTrack(track, screenStream);
            });
            // Add screen track to the peer connection
            // peerConnectionRef.current.addTrack(screenTrack);

            // Trigger renegotiation to notify the receiver of the new track
            const offer = await peerConnectionRef.current.createOffer();
            await peerConnectionRef.current.setLocalDescription(offer);

            socket.emit("screen-sharing", {
              offer,
              to: receiverIDRef.current,
            });
          }
          // sender.replaceTrack(screenTrack);
        }

        // Store the original stream (camera stream)
        // const originalRemoteStream = remoteStreamRef.current.srcObject;

        // Update local stream to show screen
        screenStreamRef.current.srcObject = screenStream;
        // screenStreamRef.current.srcObject = screenStream;

        // // Store the original stream (camera stream)
        // const originalLocalStream = localStreamRef.current.srcObject;

        // // Update local stream to show screen
        // localStreamRef.current.srcObject = originalRemoteStream;

        // Optionally, handle screen sharing end automatically when stopped by the user
        screenTrack.onended = () => {
          // originalRemoteStream.getTracks().forEach((track) => {
          //   if (track.kind === "video") {
          //     sender.replaceTrack(track); // Switch back to the original camera stream
          //   }
          // });
          // remoteStreamRef.current.srcObject = originalRemoteStream; // Restore camera stream locally
          // socket.emit("stop-screen-share", { to: receiverIDRef.current });
          // setScreenShareStart(false);

          // originalLocalStream.getTracks().forEach((track) => {
          //   if (track.kind === "video") {
          //     sender.replaceTrack(track); // Switch back to the original camera stream
          //   }
          // });
          // localStreamRef.current.srcObject = originalLocalStream; // Restore camera stream locally
          // screenStreamRef.current = null; // Clear the screen stream
          stopScreenShare();
        };
      } catch (err) {
        console.error("Error sharing screen: ", err);
      }
    };

    // const handleScreenShare = async () => {
    //   try {
    //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
    //       video: true,
    //       audio: true, // Optional: share audio as well
    //     });

    //     screenStreamRef.current = screenStream; // Store screen stream for later stopping

    //     const screenTrack = screenStream.getVideoTracks()[0];
    //     const sender = peerConnectionRef.current
    //       .getSenders()
    //       .find((s) => s.track.kind === "video");

    //     if (sender) {
    //       console.log("Sending screen to receiver");

    //       // Add screen track to the peer connection
    //       peerConnectionRef.current.addTrack(screenTrack, screenStream);

    //       // Trigger renegotiation to notify the receiver of the new track
    //       const offer = await peerConnectionRef.current.createOffer();
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       socket.emit("screen-sharing", {
    //         offer,
    //         to: receiverIDRef.current,
    //       });
    //     }

    //     // Handle screen sharing stop event
    //     screenTrack.onended = async () => {
    //       console.log("Screen sharing stopped");

    //       // Restore camera stream on end
    //       const originalStream = localStreamRef.current.srcObject;
    //       const videoTrack = originalStream.getVideoTracks()[0];
    //       videoTrack.enabled = true; // Re-enable the camera stream

    //       remoteStreamRef.current.srcObject = originalStream; // Restore remote camera stream display

    //       screenStreamRef.current = null; // Clear screen stream ref

    //       // Trigger renegotiation again after stopping screen sharing
    //       const offer = await peerConnectionRef.current.createOffer();
    //       await peerConnectionRef.current.setLocalDescription(offer);

    //       socket.emit("screen-sharing", {
    //         offer,
    //         to: receiverIDRef.current,
    //       });
    //     };
    //   } catch (err) {
    //     console.error("Error sharing screen: ", err);
    //   }
    // };

    const stopScreenShare = async () => {
      if (screenStreamRef.current) {
        console.log("Stopping screen sharing...");
        socket.emit("stop-screen-share", { to: receiverIDRef.current });
        setScreenShareStart(false);
        screenStreamRef.current = null; // Clear screen stream ref

        // const tracks = screenStreamRef.current.getTracks();
        // tracks.forEach((track) => {
        //   console.log("Stopping track:", track);
        //   track.stop(); // Stop the screen track
        // });

        // screenStreamRef.current = null; // Clear screen stream ref
        // socket.emit("stop-screen-share", { to: receiverIDRef.current });

        // // Restore the original camera stream after stopping the screen share
        // const originalStream = localStreamRef.current.srcObject;
        // const sender = peerConnectionRef.current
        //   .getSenders()
        //   .find((s) => s.track.kind === "video");

        // // if (sender) {
        // const videoTrack = originalStream.getVideoTracks()[0];
        // sender.replaceTrack(videoTrack); // Replace with camera stream
        // console.log("Replaced screen track with camera track");

        // // Optionally, renegotiate the connection to update the remote peer
        // const newOffer = await peerConnectionRef.current.createOffer({
        //   iceRestart: true, // Optional, but can help with resetting ICE candidates
        // });
        // await peerConnectionRef.current.setLocalDescription(newOffer);

        // // Send the new offer to the receiver
        // socket.emit("renegotiate-offer", {
        //   offer: newOffer,
        //   to: receiverIDRef.current,
        // });
        // }

        // localStreamRef.current.srcObject = originalStream; // Update local video element
      } else {
        console.warn("No screen stream to stop.");
      }
    };

    useImperativeHandle(ref, () => ({
      handleAnswerCall,
      handleStartCall,
      handleEndCall,
    }));

    return (
      <>
        <div className={classnames("call-window", "active")}>
          <video
            id="peerVideo"
            className={screenShareStart ? "screenShareState" : ""}
            ref={remoteStreamRef}
            autoPlay
          />
          <video id="localVideo" ref={localStreamRef} autoPlay muted />
          <video
            id="screenShare"
            style={{ visibility: !screenShareStart ? "hidden" : "" }}
            ref={screenStreamRef}
            autoPlay
          />

          {/* Screen Share Video */}
          <div className="video-control">
            <ActionButton
              key="btnScreenshare"
              icon={!screenShareStart ? faDesktop : faStop}
              // disabled={!isVideoOn}
              onClick={!screenShareStart ? handleScreenShare : stopScreenShare}
            />
            <ActionButton
              key="btnVideo"
              icon={isVideoOn ? faVideo : faVideoSlash}
              // disabled={!isVideoOn}
              onClick={() => toggleMediaTrack("video", setIsVideoOn)}
            />
            <ActionButton
              key="btnAudio"
              icon={isAudioOn ? faMicrophone : faMicrophoneSlash}
              // disabled={!isAudioOn}
              onClick={() => toggleMediaTrack("audio", setIsAudioOn)}
            />
            <ActionButton
              className="hangup"
              icon={faPhone}
              onClick={handleEndCall}
            />
          </div>
        </div>
        {isCalling && (
          <div className="overlay bluring">
            <div className="status-message animation">Calling...</div>
          </div>
        )}
        {isRinging && (
          <div className="overlay bluring">
            <div className="status-message animation">Ringing...</div>
          </div>
        )}
      </>
    );
  }
);

export default Call;
