// notification.js
const PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY;

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    return await navigator.serviceWorker.register("/sw.js");
  }
  throw new Error("Service Worker not supported");
};

export const subscribeToPushNotification = async (userId) => {
  const swRegistration = await registerServiceWorker();

  if (!swRegistration.pushManager) {
    throw new Error("Push Manager not available");
  }

  const subscription = await swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
  });
  console.log(subscription, "subscription");
  // Send the subscription to the backend server to store it
  await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/save-subscription`, {
    method: "POST",
    body: JSON.stringify({ userSubscription: subscription, userId }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Send a test notification
  // await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send-notification`, {
  //   method: "POST",
  //   body: JSON.stringify({
  //     subscription: subscription,
  //     payload: JSON.stringify({
  //       title: "Test Notification",
  //       body: "This is a test notification sent from the frontend.",
  //     }),
  //   }),
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });

  console.log("Notification sent successfully");
};

// const handleSubscribe = async () => {
//   const permissionGranted = await requestNotificationPermission();
//   if (permissionGranted) {
//     try {
//       await subscribeToPushNotification();
//       console.log("Successfully subscribed to push notifications");
//     } catch (error) {
//       console.error("Error during subscription:", error);
//     }
//   }
// };
