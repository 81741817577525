import React, { useState, useRef } from "react";
import moment from "moment";
import MenuPopup from "../menuPopup";

const MessageItem = ({
  msg,
  user,
  onDelete,
  onEdit,
  showMenu,
  setShowMenu,
  longPressTriggered,
  setLongPressTriggered,
}) => {
  const [holdTimeout, setHoldTimeout] = useState(null);

  const handleTouchStart = () => {
    if (user._id === msg?.msgByUserId) {
      setHoldTimeout(
        setTimeout(() => {
          setLongPressTriggered(msg._id);
          setShowMenu(true);
        }, 500)
      );
    }
  };

  const handleTouchEnd = () => {
    if (holdTimeout) {
      clearTimeout(holdTimeout);
    }
  };

  const handleDelete = () => {
    setShowMenu(false);
    onDelete(msg._id);
  };

  const handleEdit = () => {
    setShowMenu(false);
    const newText = prompt("Edit your message:", msg.text);
    if (newText) {
      onEdit(msg._id, newText);
    }
  };

  // Define the options for the menu
  const menuOptions = [
    // {
    //   name: "Message info",
    //   function: () => console.log("Message info clicked"),
    // },
    { name: "Edit", function: handleEdit },
    // { name: "React", function: () => console.log("React clicked") },
    // { name: "Forward", function: () => console.log("Forward clicked") },
    // { name: "Pin", function: () => console.log("Pin clicked") },
    // { name: "Star", function: () => console.log("Star clicked") },
    { name: "Delete", function: handleDelete }, // Calls the handleDelete function
  ];

  return (
    <div
      className={`p-1 py-1 rounded w-fit min-w-[100px] max-w-[280px] md:max-w-sm lg:max-w-md ${
        user._id === msg?.msgByUserId
          ? "ml-auto bg-[#075E54] text-white"
          : "bg-white"
      }`}
      // onMouseDown={handleMouseDown} // Start the long press timer
      // onMouseUp={handleMouseUp} // Cancel the timer if released early
      // onMouseLeave={handleMouseLeave} // Cancel the timer if mouse leaves
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="w-full relative">
        {msg?.imageUrl && (
          <img src={msg.imageUrl} className="w-full h-full object-scale-down" />
        )}
        {msg?.videoUrl && (
          <video
            src={msg.videoUrl}
            className="w-full h-full object-scale-down"
            controls
          />
        )}
      </div>
      <p className="px-2">{msg.text}</p>
      <div className="flex justify-between items-center mt-1">
        <span className="text-xs text-gray-300">
          {moment(msg.createdAt).format("hh:mm A")}
        </span>
        {user._id === msg?.msgByUserId && (
          <>
            <span
              className="material-symbols-outlined"
              style={{
                color: msg.deliveryStatus && msg.seen ? "#53bdeb" : undefined,
              }}
            >
              {msg.deliveryStatus ? "done_all" : "done"}
            </span>

            {showMenu && longPressTriggered === msg._id && (
              <MenuPopup options={menuOptions} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MessageItem;
