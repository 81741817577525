import React from "react";
import { IoClose } from "react-icons/io5";

const UploadPreview = ({
  message,
  handleClearUploadImage,
  handleClearUploadVideo,
}) => {
  return (
    <>
      {message.imageUrl && (
        <div className="w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden">
          <div
            className="w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600"
            onClick={handleClearUploadImage}
          >
            <IoClose size={30} />
          </div>
          <div className="bg-white p-3">
            <img
              src={message.imageUrl}
              alt="uploadImage"
              className="aspect-square w-full h-full max-w-sm m-2 object-scale-down"
            />
          </div>
        </div>
      )}
      {message.videoUrl && (
        <div className="w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden">
          <div
            className="w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600"
            onClick={handleClearUploadVideo}
          >
            <IoClose size={30} />
          </div>
          <div className="bg-white p-3">
            <video
              src={message.videoUrl}
              className="aspect-square w-full h-full max-w-sm m-2 object-scale-down"
              controls
              muted
              autoPlay
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UploadPreview;
